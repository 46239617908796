/**
 * @description 根据需求格式化时间格式
 * @export
 * @param {(string | Date)} date 时间对象
 * @param {string} [fmt='yyyy-MM-dd'] 格式参照下方映射关系
 * @returns {string} 格式化后的字符串
 */
export function useDateFormat(date: string | Date | null, fmt = 'yyyy-MM-dd'): string {
  if (!date) {
    return '';
  }
  // 2021-03-01 -> 2021/03/01
  date = typeof date === 'string' ? date.replace(/-/g, '/') : date;
  date = new Date(date);
  if (!date.getDate()) {
    return '';
  }

  // 格式化对应字段映射
  const o: {
    [key: string]: number;
  } = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };

  // 格式化年份 RegExp.$1指向多次匹配的y字符 substr则是为了兼容不显示完整年份的情况
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
  }

  // 根据映射遍历格式所生成对应的正则进行匹配替换
  Object.keys(o).forEach(k => {
    // 动态生成时间格式单个字段的正则
    if (new RegExp(`(${k})`).test(fmt)) {
      const len = (`${o[k]}`).length; // 获取当前时间对应字段长度
      if (k === 'S') {
        fmt = fmt.replace(RegExp.$1, `${o[k]}`);
      } else {
        /*
         * 因为除了年份和毫秒字段特殊以外 一般用于显示时间的字段长度都为2 比如 2021-12-01 11:12:13
         * 如果当前匹配到的时间格式字段 长度不为1 则进行补齐0的操作
         * 比如 需要格式化的为MM RegExp.$1.length匹配为2 获取到的月份为3 len为1 -> `003`.substr(1) 实现了补齐0的操作
         * 反之默认替换为Date对应的初始值
         */
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? `${o[k]}` : `00${o[k]}`.substr(len));
      }
    }
  });

  return fmt;
}
