import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "component-index-table" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "dialog-footer" }
const _hoisted_5 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.tableData,
      border: "",
      size: "medium",
      class: "table",
      "row-class-name": "table__column"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "#",
          "min-width": "6"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", {
              class: _normalizeClass(['status-code', scope.row.status_code])
            }, null, 2)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          "min-width": "10"
        }),
        _createVNode(_component_el_table_column, {
          prop: "status",
          label: "状态",
          "min-width": "10"
        }),
        _createVNode(_component_el_table_column, {
          prop: "name",
          label: "名称"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              type: "text",
              disabled: !scope.row.url,
              onClick: ($event: any) => (_ctx.goTask(scope.row.url))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.name), 1)
              ]),
              _: 2
            }, 1032, ["disabled", "onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "设计文档",
          "min-width": "10"
        }, {
          default: _withCtx((scope) => [
            (scope.row.design_url)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  type: "text",
                  onClick: ($event: any) => (_ctx.goTask(scope.row.design_url))
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" 文档URL ")
                  ])),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "跟进数量",
          "min-width": "10"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              type: "text",
              disabled: !scope.row.url,
              onClick: ($event: any) => (_ctx.goFollow(scope.row.id))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.followup_counts), 1)
              ]),
              _: 2
            }, 1032, ["disabled", "onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "type",
          label: "类型",
          "min-width": "10"
        }),
        _createVNode(_component_el_table_column, {
          label: "工期",
          "min-width": "20"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", null, _toDisplayString(scope.row.started_at), 1),
            _createElementVNode("div", null, _toDisplayString(scope.row.finished_at || '--'), 1),
            _createVNode(_component_el_button, {
              type: "text",
              class: "header",
              size: "small",
              onClick: ($event: any) => (_ctx.showEdit(scope.row))
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" 编辑 ")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "创建信息",
          "min-width": "20"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", null, _toDisplayString(scope.row.creator_name), 1),
            _createElementVNode("div", null, _toDisplayString(scope.row.created_at), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "操作",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsList, (option) => {
              return (_openBlock(), _createElementBlock("div", {
                key: option.label
              }, [
                _createVNode(_component_el_button, {
                  type: "text",
                  size: "small",
                  onClick: ($event: any) => (option.method(scope.row.id))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(option.label), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
            }), 128)),
            (scope.row.status_code !== 'deleted' && scope.row.status_code !== 'completed')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_el_button, {
                    type: "text",
                    size: "small",
                    onClick: ($event: any) => (_ctx.setDialog(scope.row, 'FINISH'))
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" 完成任务 ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"])
                ]))
              : _createCommentVNode("", true),
            (scope.row.status_code !== 'deleted' && scope.row.status_code !== 'completed')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_el_button, {
                    type: "text",
                    size: "small",
                    style: {"color":"red"},
                    onClick: ($event: any) => (_ctx.setDialog(scope.row, 'DELETE'))
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" 删除任务 ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_el_dialog, {
      title: "修改工期",
      "model-value": _ctx.workTime.isShowEdit,
      width: "20%",
      center: "",
      "destroy-on-close": "",
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.workTime.isShowEdit = false))
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_el_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.workTime.isShowEdit = false))
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode("取 消")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("确 定")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "form",
          model: _ctx.workTime,
          rules: _ctx.rules
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "开始:",
              prop: "started_at"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.workTime.started_at,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.workTime.started_at) = $event)),
                  type: "date",
                  placeholder: "开始时间"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "结束:",
              prop: "finished_at",
              class: "stage-end-picker"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.workTime.finished_at,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.workTime.finished_at) = $event)),
                  type: "date",
                  placeholder: "结束时间"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["model-value"]),
    _createVNode(_component_el_dialog, {
      title: _ctx.controlDialog.title,
      "model-value": _ctx.controlDialog.isShow,
      width: "20%",
      center: "",
      "destroy-on-close": "",
      onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.controlDialog.isShow = false))
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_el_button, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.controlDialog.isShow = false))
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode("取 消")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.submitControl
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode("确 定")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString(_ctx.controlDialog.text), 1)
      ]),
      _: 1
    }, 8, ["title", "model-value"])
  ]))
}