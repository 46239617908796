
import { deleteWork, finishWork, updateWorkTime } from '@/api';
import { Message } from '@/common/utils';
import { Work } from '@/types/task/work';
import {
  defineComponent, getCurrentInstance, PropType, reactive, ref,
} from 'vue';
import { usePages } from '@/hooks/usePages';
import { useFormRequired } from '@/hooks/useFormRequired';
import { useDateFormat } from '@/hooks/useDateFormat';
import { useRouter } from 'vue-router';

enum ControlEnum {
  delete = 'DELETE',
  finish = 'FINISH',
}

type ControlTask = 'DELETE' | 'FINISH';

interface ControlDialog {
  isShow: boolean;
  title: string;
  text: string;
  type: ControlTask;
  id: number;
}

export default defineComponent({
  props: {
    // 表格数据
    tableData: {
      type: Array as PropType<Array<Work>>,
      required: true,
      default: () => [],
    },
  },
  emits: ['refresh'],
  setup(_, { emit }) {
    const router = useRouter();
    /* 是否显示操作任务弹出框 */
    const controlDialog = ref<ControlDialog>({
      isShow: false,
      title: '',
      text: '',
      type: 'DELETE',
      id: 0,
    });
    /* 删除/完成任务按钮 */
    const optionsList = usePages(['create', 'detail']); // 迭代操作按钮List
    // 修改工期所需字段
    const workTime = reactive({
      finished_at: '',
      started_at: '',
      id: null as number | null,
      isShowEdit: false,
    });
    const isRequired = useFormRequired({
      started_at: '开始时间',
    });
    const rules = reactive({
      ...isRequired,
    });

    /* 删除/完成任务 */
    const submitControl = async () => {
      const { type, id } = controlDialog.value;
      const apiMap = {
        [ControlEnum.finish]: {
          api: finishWork,
          body: {
            is_completed: true,
          },
        },
        [ControlEnum.delete]: {
          api: deleteWork,
          body: null,
        },
      };
      const { api, body } = apiMap[type];
      const { code } = await api(id, body);
      if (code === 0) {
        Message('操作成功');
        emit('refresh');
        controlDialog.value.isShow = false;
      } else {
        Message('操作失败', 'error');
      }
    };

    const setDialog = ({ id, name, finished_at }: Work, type: ControlTask) => {
      if (type !== 'DELETE' && !finished_at) {
        Message('必须录入工期的起止时间，才可完成任务。', 'error');
        return;
      }
      const title = type === 'DELETE' ? '删除任务' : '完成任务';
      controlDialog.value = {
        id,
        type,
        title,
        text: `任务名称：${name}`,
        isShow: true,
      };
    };

    // 显示编辑框 自动带入表格数据
    const showEdit = ({ started_at, finished_at, id }: {
      started_at: string;
      finished_at: string;
      id: number;
    }) => {
      workTime.finished_at = finished_at || '';
      workTime.started_at = started_at || '';
      workTime.id = id;
      workTime.isShowEdit = true;
    };

    // 查看跟进
    const goFollow = (id: number) => router.push(`/follow?id=${id}`);

    // 修改工期
    const updateTime = async () => {
      const {
        started_at: start, finished_at: end, id,
      } = workTime;
      const [startTime, endTime] = [
        new Date(start).getTime(),
        new Date(useDateFormat(end)).getTime(), // 此处是为了避免时区信息丢失导致的时间戳计算不一致
      ];
      if (endTime && endTime < startTime) {
        Message('结束时间应大于开始时间', 'error');
        return;
      }
      const [started_at, finished_at] = [
        useDateFormat(start),
        useDateFormat(end),
      ];
      /* 结束时间是可选的 */
      const body = { started_at };
      finished_at && Reflect.set(body, 'finished_at', finished_at);
      const { code } = await updateWorkTime(id, body);
      if (code === 0) {
        Message('修改成功');
        workTime.isShowEdit = false;
        emit('refresh');
      }
    };

    const ctx = getCurrentInstance()?.proxy;
    // form表单校验
    const submit = () => {
      (ctx?.$refs.form as any).validate((valid: any) => {
        if (valid) {
          updateTime();
        } else {
          return false;
        }
      });
    };

    const goTask = (url: string) => {
      window.open(url);
    }; // 跳转具体的tapd / 自定义URL
    return {
      optionsList,
      goTask,
      goFollow,
      workTime,
      rules,
      submit,
      showEdit,
      controlDialog,
      setDialog,
      submitControl,
    };
  },
});
