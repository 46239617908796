import router from '@/router';

type config = 'member' | 'stage' | 'follow' | 'create' | 'taskBug' | 'detail'

export function usePages(configs: config[]) {
  const goMember = (id: string) => {
    router.push(`/member?id=${id}`);
  }; // 查看人员

  const goStage = (id: string) => {
    router.push(`/stage?id=${id}`);
  }; // 查看阶段

  const gofollowUp = (id: string) => {
    router.push(`/follow?id=${id}`);
  }; // 查看跟进

  const goCreateFollow = (id: string) => {
    router.push(`/create-follow?id=${id}`);
  }; // 新建跟进

  const goFollowDetail = (id: string) => {
    router.push(`/follow?id=${id}`);
  }; // 查看详情

  const goTaskBugList = (id: string) => {
    router.push(`/taskBug?id=${id}`);
  };// Bug情况
  const options = {
    member: {
      label: '查看人员',
      method: goMember,
    },
    stage: {
      label: '查看阶段',
      method: goStage,
    },
    follow: {
      label: '查看跟进',
      method: gofollowUp,
    },
    create: {
      label: '新增跟进',
      method: goCreateFollow,
    },
    taskBug: {
      label: 'BUG情况',
      method: goTaskBugList,
    },
    detail: {
      label: '查看详情',
      method: goFollowDetail,
    },
  };

  return configs.map(config => Reflect.get(options, config));
}
