import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "page-task-index" }
const _hoisted_2 = { class: "tab-box" }
const _hoisted_3 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_index_table = _resolveComponent("index-table")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "title" }, " 任务列表 ", -1)),
    _createVNode(_component_el_form, {
      inline: "",
      model: _ctx.formInline,
      class: "form-inline",
      rules: _ctx.rules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "ID:",
          prop: "id"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.formInline.id,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInline.id) = $event)),
              placeholder: "任务ID"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "名称:",
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.formInline.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formInline.name) = $event)),
              placeholder: "请输入名字模糊检索"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "人员:",
          prop: "userName"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_autocomplete, {
              modelValue: _ctx.autoComplete.userName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.autoComplete.userName) = $event)),
              class: "inline-input",
              "select-when-unmatched": "",
              "trigger-on-focus": "",
              "fetch-suggestions": _ctx.queryMember,
              placeholder: "人员",
              debounce: 100,
              onSelect: _ctx.handleMemberSelect
            }, null, 8, ["modelValue", "fetch-suggestions", "onSelect"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "创建人:",
          prop: "creatorName"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_autocomplete, {
              modelValue: _ctx.autoComplete.creatorName,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.autoComplete.creatorName) = $event)),
              class: "inline-input",
              "select-when-unmatched": "",
              "trigger-on-focus": "",
              "fetch-suggestions": _ctx.queryMember,
              placeholder: "请选择创建人",
              debounce: 100,
              onSelect: _ctx.handleCreatorSelect
            }, null, 8, ["modelValue", "fetch-suggestions", "onSelect"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "阶段:",
          prop: "stageName"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_autocomplete, {
              modelValue: _ctx.autoComplete.stageName,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.autoComplete.stageName) = $event)),
              class: "inline-input",
              "select-when-unmatched": "",
              "trigger-on-focus": "",
              "fetch-suggestions": _ctx.queryStage,
              debounce: 100,
              placeholder: "阶段",
              onSelect: _ctx.handleStageSelect
            }, null, 8, ["modelValue", "fetch-suggestions", "onSelect"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "来源类型:" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.formInline.type,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formInline.type) = $event)),
              clearable: "",
              placeholder: "来源类型"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sourceList, (source) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: source.label,
                    label: source.label,
                    value: source.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "任务时间:" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.datePicker,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.datePicker) = $event)),
              type: "datetimerange",
              "range-separator": "至",
              "unlink-panels": "",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "完成时间:" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.finishDatePicker,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.finishDatePicker) = $event)),
              type: "datetimerange",
              "range-separator": "至",
              "unlink-panels": "",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.refresh
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" 查询 ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.formInline.status,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formInline.status) = $event)),
        type: "card",
        onTabChange: _ctx.refresh
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: tab.name,
              label: tab.label,
              name: tab.name
            }, {
              default: _withCtx(() => [
                _createVNode(_component_index_table, {
                  "table-data": _ctx.tableData,
                  onRefresh: _ctx.refresh
                }, null, 8, ["table-data", "onRefresh"])
              ]),
              _: 2
            }, 1032, ["label", "name"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onTabChange"]),
      _createVNode(_component_el_button, {
        type: "primary",
        class: "header",
        plain: "",
        onClick: _ctx.goCreate
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createTextVNode(" 新增工作任务 ")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_pagination, {
        "page-info": _ctx.pageInfo,
        onPageChange: _ctx.pageChange
      }, null, 8, ["page-info", "onPageChange"])
    ])
  ]))
}