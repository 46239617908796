
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  props: {
    // 分页信息
    pageInfo: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  emits: ['pageChange'],
  setup(props, { emit }) {
    const { pageInfo } = reactive(props);
    // 页数修改
    const handleCurrentChange = (currentPage: number): void => emit('pageChange', { ...pageInfo, currentPage });

    return {
      handleCurrentChange,
    };
  },
});
