
import { getStage, getUser, getWorkList } from '@/api';
import { Message } from '@/common/utils';
import { Member } from '@/types/task/member';
import { Stage } from '@/types/task/stage';
import { Work } from '@/types/task/work';
import {
  defineComponent, onMounted, onUnmounted, reactive, ref,
} from 'vue';
import { useRouter } from 'vue-router';
import Pagination from '@/components/pagination.vue';
import { useDateFormat } from '@/hooks/useDateFormat';
import { useStore } from 'vuex';
import IndexTable from './components/index-table.vue';

export default defineComponent({
  components: {
    IndexTable,
    Pagination,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const sourceList = [
      { label: '业务迭代', value: 'project' },
      { label: '优化重构', value: 'optimization' },
      { label: '缺陷', value: 'bug' },
      { label: '其他', value: 'custom' },
    ]; // 来源List
    // 搜索条件表单
    const formInline = reactive({
      id: null as null | number, // 任务ID
      stage_id: null as null | number, // 默认没有的情况
      user_id: null as null | number, // 默认没有的情况
      status: 'all',
      type: '',
      name: '',
      creator_id: null as null | number,
    });
    /* 任务时间选择器 */
    const datePicker = ref<Array<string>>([]);
    /* 完成时间选择器 */
    const finishDatePicker = ref<Array<string>>([]);

    // 模糊匹配自动填充字段
    const autoComplete = reactive({
      stageName: '',
      userName: '',
      creatorName: '',
    });
    const tableData = ref<Array<Work & { isShowEdit: boolean }>>([]);
    /*
    * 缓存的两个数组来进行后期的表单校验
    * memberArr：模糊匹配用户列表
    * stageArr： 模糊匹配阶段列表
    */
    const [memberArr, stageArr] = [ref<Array<Member & { value: string }>>([]), ref<Array<Stage & { value: string }>>([])];
    // 过滤是否从下拉选择中获取用户信息
    const filterUser = (rule: any, value: string, callback: (...args: any) => any) => {
      const index = memberArr.value.findIndex(member => member.value === autoComplete.userName);
      if (autoComplete.userName && index === -1) {
        callback(new Error('请从下拉选择中添加人员'));
      } else {
        callback();
      }
    };
    // 过滤是否从下拉选择中获取创建用户信息
    const filterCreator = (rule: any, value: string, callback: (...args: any) => any) => {
      const index = memberArr.value.findIndex(member => member.value === autoComplete.creatorName);
      if (autoComplete.creatorName && index === -1) {
        callback(new Error('请从下拉选择中添加人员'));
      } else {
        callback();
      }
    };
    // 过滤是否从下拉选择中获取阶段信息
    const filterStage = (rule: any, value: string, callback: (...args: any) => any) => {
      const index = stageArr.value.findIndex(stage => stage.value === autoComplete.stageName);
      if (autoComplete.stageName && index === -1) {
        callback(new Error('请从下拉选择中添加阶段'));
      } else {
        callback();
      }
    };
    // form表单规则
    const rules = reactive({
      stageName: [
        { validator: filterStage },
      ],
      userName: [
        { validator: filterUser },
      ],
      creatorName: [
        { validator: filterCreator },
      ],
    });

    const tabs = [
      { label: '全部', name: 'all' },
      { label: '正常', name: 'active' },
      { label: '已完成', name: 'completed' },
      { label: '已删除', name: 'deleted' },
    ]; // 渲染tabs数组

    // 分页信息
    const pageInfo = reactive({
      currentPage: 1,
      pageSize: 10,
      total: 0,
    });

    // 获取数据
    const search = async () => {
      // 因为这里提交的搜索请求需要有具体的user_id/stage_id 所以要用文本框的值来匹配是否选取模糊搜索结果中回调的id
      const findMember = memberArr.value.findIndex(member => member.value === autoComplete.userName);
      const findStage = stageArr.value.findIndex(stage => stage.value === autoComplete.stageName);
      const findCreator = memberArr.value.findIndex(member => member.value === autoComplete.creatorName);
      if (findMember === -1) {
        formInline.user_id = null;
      }
      if (findStage === -1) {
        formInline.stage_id = null;
      }
      if (findCreator === -1) {
        formInline.creator_id = null;
      }
      /* 格式化任务时间 */
      let [started_at, finished_at] = datePicker.value || [];
      started_at = started_at ? useDateFormat(started_at) : '';
      finished_at = finished_at ? useDateFormat(finished_at) : '';

      /* 格式化完成时间 */
      let [complete_started_at, complete_finished_at] = finishDatePicker.value || [];
      complete_started_at = complete_started_at ? useDateFormat(complete_started_at) : '';
      complete_finished_at = complete_finished_at ? useDateFormat(complete_finished_at) : '';
      const { code, data } = await getWorkList({
        ...formInline,
        started_at,
        finished_at,
        complete_started_at,
        complete_finished_at,
        status: formInline.status === 'all' ? null : formInline.status,
        type: formInline.type || null,
        page: pageInfo.currentPage,
        count: pageInfo.pageSize,
      });
      if (code === 0) {
        pageInfo.total = data.total_count;
        tableData.value = data.work.map(item => ({
          ...item,
          isShowEdit: false,
        }));
      }
    };

    const queryParams = reactive({
      page: 1,
      count: 10,
    }); // 模糊搜索字段

    // 分页回调
    const pageChange = ({ currentPage }: { currentPage: number }): void => {
      pageInfo.currentPage = currentPage;
      search();
    };

    // 获取模糊匹配阶段信息
    const queryStage = async (name: string, cb: (...args: any) => any) => {
      const { code, data } = await getStage({ ...queryParams, name });
      if (code === 0) {
        // 缓存起来 用于表单校验
        stageArr.value = data.stages.map(stage => ({
          ...stage,
          value: stage.name,
        }));
        cb(stageArr.value);
      } else {
        Message('获取阶段失败', 'error');
      }
    };

    const handleStageSelect = (item: Stage) => {
      // 在没有相关模糊搜索下 将id置为null
      formInline.stage_id = item.id || null;
    };

    // 获取模糊匹配用户信息
    const queryMember = async (name: string, cb: (...args: any) => any) => {
      const { code, data } = await getUser({
        ...queryParams,
        name,
        status: 'active',
      });
      if (code === 0) {
        // 缓存起来 用于表单校验
        memberArr.value = data.users.map(member => ({
          ...member,
          value: member.name,
        }));
        cb(memberArr.value);
      } else {
        Message('获取用户失败', 'error');
      }
    };

    const handleMemberSelect = (item: Member) => {
      // 在没有相关模糊搜索下 将id置为null
      formInline.user_id = item.id || null;
    };
    const handleCreatorSelect = (item: Member) => {
      // 在没有相关模糊搜索下 将id置为null
      formInline.creator_id = item.id || null;
    };

    // 重新筛选数据
    const refresh = (): void => {
      pageInfo.currentPage = 1;
      search();
    };

    const goCreate = () => { router.push('/create'); }; // 新建工作任务

    onMounted(async () => {
      // 创建人检索默认为自身账号
      await store.dispatch('user/getUserDetail');
      memberArr.value = [
        ...memberArr.value,
        {
          id: store.getters['user/getCurrentUserDetail'].id,
          name: store.getters['user/getCurrentUserDetail'].name,
          value: store.getters['user/getCurrentUserDetail'].name,
          avatar: '',
          email: '',
          mobile_number: 0,
          type: '',
          type_code: '',
        },
      ];
      formInline.creator_id = store.getters['user/getCurrentUserDetail'].id;
      autoComplete.creatorName = store.getters['user/getCurrentUserDetail'].name;
      // 如果是从人员列表过来的需要带上用户信息
      const user: { id: number; name: string; type: 'personnel' | 'currentWork'} = { ...store.state.personnel.user };
      if (user.type === 'personnel') {
        memberArr.value = [
          ...memberArr.value,
          {
            id: user.id,
            name: user.name,
            value: user.name,
            avatar: '',
            email: '',
            mobile_number: 0,
            type: '',
            type_code: '',
          },
        ];
        formInline.user_id = user.id;
        autoComplete.userName = user.name;
        formInline.creator_id = null;
        autoComplete.creatorName = '';
      }

      // 如果是从人员管理-当前任务列表过来的
      const wid = store.state.personnel.work.id;
      if (wid) {
        formInline.id = wid;
        formInline.creator_id = null;
        autoComplete.creatorName = '';
      }
      await search();
    });

    onUnmounted(() => {
      // 清空从用户列表带来的用户信息
      store.commit('personnel/setUserDetail', {
        id: null,
        name: '',
      });

      // 清空从人员管理-当前任务列表带来的用户信息
      store.commit('personnel/setWorkId', null);
    });

    return {
      tabs,
      tableData,
      rules,
      queryStage,
      handleStageSelect,
      queryMember,
      handleMemberSelect,
      pageInfo,
      autoComplete,
      sourceList,
      formInline,
      search,
      refresh,
      pageChange,
      goCreate,
      datePicker,
      finishDatePicker,
      handleCreatorSelect,
    };
  },
});
