import { Required } from '@/types/task/create';

interface PropMap {
  [key: string]: string;
}
/**
 * @description 表单非空字段规则映射
 * @export
 * @param {PropMap} propMap 映射的字段key -> 提示文字value
 * @returns {Required} Element Form Rules
 */
export function useFormRequired(propMap: PropMap): Required {
  const obj = Object.create(null);
  Object.keys(propMap).map(key => (
    Reflect.set(obj, key, [
      { required: true, message: `请添加${Reflect.get(propMap, key)}` },
    ])
  ));
  return obj;
}
